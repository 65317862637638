.social{
    display: flex;
    justify-content: right;
    align-items: center;
}
.social li{
    margin: 0 1em;
}.social svg{
    font-size: 1.5em;
    cursor: pointer;
}
.socialCorpo {
    width: 100%;
}
