.divcontainer {
    margin: 0 auto;
    max-width: 70%;
    display: flex;
    flex-wrap: wrap;
    background-color: rgb(239, 242, 245);
    -webkit-border-top-right-radius: 40px;
    -webkit-border-bottom-left-radius: 40px;
    -moz-border-radius-topright: 40px;
    -moz-border-radius-bottomleft: 40px;
    border-top-right-radius: 40px;
    border-bottom-left-radius: 40px;
    margin-top: 70px;
    padding: 33px;
    border: solid #3e274d 5px;


}

a {
    width: 100%;
}

.perfil,
.conteudo {
    width: 50%;
    min-width:300px;
}