body{
    margin: 0;
    background: linear-gradient(90deg, rgba(152,75,215,0.9360119047619048) 0%, rgba(211,174,235,0.938813025210084) 41%, rgba(190,140,222,0.8939950980392157) 100%);
}
* {
    padding:0;
    margin:0;
    vertical-align:baseline;
    list-style:none;
    border:0;
    }